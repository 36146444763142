<template>
  <div class="hello" style="margin:auto;">
    <h1>{{ msg }}</h1>
    <div>
      <v-row>
        <v-card>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>

            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              class="coursePdfDialog"
            >
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" @click="toggleEnter()">
                  <v-icon>mdi-fullscreen</v-icon>
                </v-btn>
              </template>
              <fullscreen :fullscreen.sync="fullscreen" ref="fullscreenref" background="#EEE">
                <v-card v-on:keyup.right="next" v-on:keyup.left="prev">
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="toggleExit(); dialog = false; ">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items style="overflow-x: auto; overflow-y: hidden;">
                      <v-row>
                        <v-col v-if="checkList()">
                          <v-select :items="pdfList" v-on:change="changePdfPath" label="Documents"></v-select>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            v-model.number="page"
                            @input="checkNum"
                            :value="numPages"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-btn dark text>{{getLabel()}}</v-btn>
                      <v-btn dark text @click="prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn dark text @click="next()">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-btn dark text @click="rotate += 90">
                        <v-icon>mdi-rotate-right</v-icon>
                      </v-btn>
                      <v-btn dark text @click="rotate -= 90">
                        <v-icon>mdi-rotate-left</v-icon>
                      </v-btn>
                      <v-btn dark text @click="$refs.pdf.print()">
                        <v-icon dark>mdi-printer</v-icon>
                      </v-btn>
                      <v-btn dark text @click="downloadAxios()">
                        <v-icon dark>mdi-download</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <div style="width: 100%;">
                            <div
                              v-if="loadedRatio > 0 && loadedRatio < 1"
                              style="background-color: green; color: white; text-align: center"
                              :style="{ width: loadedRatio * 100 + '%' }"
                            >{{ Math.floor(loadedRatio * 100) }}%</div>

                            <pdf
                              v-if="show"
                              ref="pdf"
                              style="border: 1px solid grey"
                              :src="src"
                              :page="page"
                              :rotate="rotate"
                              @progress="loadedRatio = $event"
                              @error="error"
                              @num-pages="numPages = $event"
                              @link-clicked="page = $event"
                              @page-loaded="toggleEnter"
                              @loaded="toggleEnter"
                            ></pdf>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </fullscreen>
            </v-dialog>
          </v-toolbar>
          <v-toolbar-items style="overflow: auto;">
            <v-col v-if="checkList()">
              <v-select :items="pdfList" v-on:change="changePdfPath" label="Documents"></v-select>
            </v-col>
            <v-col>
              <v-text-field
                class="p-0 m-0"
                type="number"
                v-model.number="page"
                @input="checkNum"
                :value="numPages"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-btn text>{{getLabel()}}</v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="rotate += 90">
                <v-icon>mdi-rotate-right</v-icon>
              </v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="rotate -= 90">
                <v-icon>mdi-rotate-left</v-icon>
              </v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="$refs.pdf.print()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col>

            <v-col>
              <v-btn text @click="downloadAxios()">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-toolbar-items>
          <v-card-text>
            <pdf
              v-if="show"
              ref="pdfprew"
              style="border: 1px solid grey;"
              :src="source"
              :page="page"
              :rotate="rotate"
              @progress="loadedRatio = $event"
              @error="error"
              @num-pages="numPages = $event"
              @link-clicked="page = $event"
            ></pdf>
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import axios from "axios";

export default {
  name: "PdfViewer",
  props: {
    msg: String,
    source: String,
    title: String,
    pdfList: Array,
    cardHeader: String
  },
  components: {
    pdf
  },
  data() {
    return {
      dialog: false,
      show: true,
      loadedRatio: 0,
      page: 1,
      fullscreen: false,
      numPages: 0,
      rotate: 0,
      error: "",
      src: this.source
    };
  },
  methods: {
    checkList() {
      return this.pdfList ? this.pdfList.length > 0 : false;
    },
    changePdfPath(a) {
      this.src = a;
    },
    toggleEnter() {
      if (this.$refs["fullscreenref"]) {
        this.$refs["fullscreenref"].enter();
      }
    },
    toggleExit() {
      this.dialog = false;
    },
    getLabel: function() {
      return "/" + this.numPages;
    },
    prev() {
      if (this.page > 1) {
        this.page--;
      } else {
        this.page = 1;
      }
    },
    next() {
      if (this.page < this.numPages) {
        this.page++;
      } else {
        this.page = 1;
      }
    },
    checkNum() {
      let page = parseFloat(this.page);
      if (page < 1) {
        this.page = 1;
      }
      if (page > this.numPages) {
        this.page = this.numPages;
      }
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.src); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    download() {
      this.$http({
        method: "get",
        url: this.source,
        responseType: "arraybuffer"
      })
        .then(response => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
    downloadAxios() {
      axios({
        method: "get",
        url: this.src,
        responseType: "arraybuffer"
      })
        .then(response => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.coursePdfDialog {
  z-index: 99999;
}
</style>
