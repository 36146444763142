<template>
  <div style="height: 100%; width: 100%">
    <video ref="videoPlayer" class="video-js" crossorigin="anonymous" width= "1000" height= "1000" ></video>
  </div>
</template>

<script>
import "@silvermine/videojs-quality-selector/dist/css/quality-selector.css";
import videojs from "video.js";
// import 'videojs-vr/dist/videojs-vr.js';
import "videojs-event-tracking";
import qualitySelector from "@silvermine/videojs-quality-selector";

let intervalId;

export default {
  name: "VideoPlayer",
  props: {
    contentId: Number,
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      watchDuration: 0,
      videoDuration: null,
      isEnded: false,
      userId: JSON.parse(localStorage.getItem("userInfo")).Uid,
      milestones: {},
    };
  },

  mounted() {
    qualitySelector(videojs, { showQualitySelectionLabelInControlBar: true });
    this.player = videojs(this.$refs.videoPlayer, this.options, this.ready);
    this.player.controlBar.addChild("QualitySelector");

   // set player listeners
    this.setPlayerListeners();
    this.startInterval();
    window.__player = this.player;
  },

  beforeDestroy() {
    this.updateDbWithCurrent();

    if (this.player) {
      this.player.dispose();
    }
    this.clearInterval();
  },

  methods: {
    startInterval() {
      //cal db periodically to keep current time.
      intervalId = setInterval(() => {
        this.updateDbWithCurrent();
      }, 5000);
    },

    checkInterval() {
      if(!intervalId){
        this.startInterval();
      }
    },

    clearInterval () {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    },

    ready() {
      this.$emit("ready", this.player);
    },

    getDuration() {
      return this.player && this.player.duration();
    },

    getCurrentTime() {
      let currentTime = null;

      try {
        currentTime = this.player.currentTime()
      } catch (error) {
        // console.log(error);
      }

      return currentTime;
    },

    setPlayerListeners() {
      this.player.on("loadedmetadata", (data) => {
        this.getCurrentData();
      });
      this.player.on("timeupdate", (data) => {
        this.updateMilestones(data);
        this.checkInterval();
      });
      this.player.on("ended", (data) => {
        this.clearInterval();
        this.updateDbWithCurrent();
      });
    },

    getCurrentData() {
      let query = "/";
      query = query + `${this.userId}/`;
      query = query + `${this.contentId}`;

      this.$goc.request.get(
        this.$enums.API.GetVideoContent + query,
        (response) => {
          console.log(response);
          if (response.IsSuccess) {
            //if user isn't playing but waiting in the page, service call will be done periodically. So, milestones will send to db as default {}
            //if is there is no data for that user in response, milestones will be null or undefined
            if (
              response.Result.Milestones == null ||
              response.Result.Milestones == "{}" || (typeof response.Result.Milestones === "undefined")
            ) {
              //if result is null, user is watching the video for te first time, so milestones should be created
              this.setMilestones();
            } else {
              this.watchDuration = parseInt(response.Result.WatchDuration);
              this.milestones = JSON.parse(response.Result.Milestones);
              this.isEnded = response.Result.IsEnded;
              //setting starting time to the last point which is taken from database
              this.player.currentTime(this.watchDuration);
            }
          }
        },
        null,
        true
      );
    },

    setMilestones() {
      // when meta data loaded create milestones for video duration
      this.videoDuration = this.getDuration();
      if (this.videoDuration < 180) {
        for (let i = 5; i < this.videoDuration; i += 10) {
          this.milestones[`m-${i}`] = false;
        }
      } else {
        for (let i = 10; i < this.videoDuration; i += 30) {
          this.milestones[`m-${i}`] = false;
        }
      }
    },

    updateMilestones(data) {
      this.watchDuration = this.getCurrentTime();
      if (this.milestones.hasOwnProperty(`m-${Math.floor(this.watchDuration)}`)) {
        //to prevent unnecessary db call,checking related milestone is true or not.
        if (this.milestones[`m-${Math.floor(this.watchDuration)}`] != true) {
          this.milestones[`m-${Math.floor(this.watchDuration)}`] = true;
          this.checkCompleteStatus();
          this.updateDbWithCurrent();
        }
      }
    },

    checkCompleteStatus() {
      const isWatched = (currentValue) => currentValue === true;
      //this code will be prevent unceserray db call, if video is watching for more than once.
      if (
        Object.values(this.milestones).every(isWatched) &&
        this.isEnded != true
      ) {
        this.isEnded = true;
      }
    },

    updateDbWithCurrent() {
      //when the page is opened, video is not loaded yet. "timeupdate" is fired, but duration is NAN. so that this if condition check is added.
      if (this.getDuration() != null && this.getCurrentTime() != null) {
        let body = {
          userId: this.userId,
          contentId: this.contentId,
          videoDuration: { Seconds: this.getDuration(), Nanos: 0 },
          watchDuration: { Seconds: this.getCurrentTime(), Nanos: 0 },
          isEnded: this.isEnded,
          milestones: JSON.stringify(this.milestones),
        };
        this.$goc.request.post(
          this.$enums.API.UpdateVideoTime,
          body,
          () => {},
          true
        );
      }
    },
  },
};
</script>
