<template>
  <div id="add-content" class="p-0">
    <v-container class="col-12 p-0">
      <v-row>
        <v-col cols="12" sm="9" class="p-0">
          <v-card class="col-12" v-if="indexedItems.length > 0">
            <v-window v-model="step">
              <v-window-item
                v-for="(content, index) in indexedItems"
                :key="index"
                :value="content.index"
              >
                <v-card-title
                  class="title font-weight-regular justify-space-between"
                >
                  <div class="d-flex">
                    <span>{{ content.Name }}</span>
                    <div
                      v-if="
                        content.Type === 1 &&
                        content.ContentProperty &&
                        content.ContentProperty.Url
                      "
                    >
                      <v-btn
                        dark
                        class="ml-4"
                        :loading="
                          videoLoadings[content.ContentProperty.id] &&
                          !!getProgress(content.ContentProperty.id)
                        "
                        @click="
                          downloadVideo(
                            content.ContentProperty.Url,
                            content.ContentProperty.id
                          )
                        "
                        :class="{
                          'loading-btn':
                            videoLoadings[content.ContentProperty.id] &&
                            !!getProgress(content.ContentProperty.id),
                        }"
                      >
                        <v-icon>mdi-download</v-icon>
                        <template v-slot:loader>
                          <span
                            v-if="getProgress(content.ContentProperty.id)"
                            class="mx-2 my-2"
                          >
                            {{ getProgress(content.ContentProperty.id) }}</span
                          >
                          <v-progress-circular
                            indeterminate
                            size="24"
                          ></v-progress-circular>
                        </template>
                      </v-btn>
                    </div>
                  </div>
                  <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="content.index"
                  />
                </v-card-title>
                <v-card-text class="detail-text">
                  <v-flex v-if="content.Type === 1">
                    <video-player
                      v-if="step === index + 1"
                      ref="videoPlayer"
                      :options="playerOptions"
                      @ready="onPlayerReady"
                      :contentId="selectedData[0] && selectedData[0].Id"
                    />
                  </v-flex>

                  <div v-if="content.Type === 2">
                    <ckeditor
                      :editor="editor"
                      v-model="content.ContentProperty.HtmlContent"
                      disabled
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <iframe
                    v-if="content.Type === 3"
                    ref="unrealIframe"
                    :src="content.ContentProperty.Url"
                    scrolling="no"
                    frameborder="0"
                    allowtransparency="true"
                    style="
                      width: 0;
                      min-width: 100% !important;
                      height: 680px !important;
                    "
                    allowfullscreen
                    :height="iframeHeight"
                  ></iframe>

                  <exam-component
                    v-if="content.Type === 4"
                    :exam="
                      JSON.parse(content.ContentProperty.CustomContent).ExamId
                    "
                    :type="getType()"
                  ></exam-component>

                  <v-flex v-if="content.Type === 5">
                    <PdfViewer
                      cardHeader="Pdf View Card"
                      msg=""
                      :source="content.ContentProperty.Url"
                      :title="content.Name"
                    />
                  </v-flex>
                </v-card-text>
              </v-window-item>
            </v-window>

            <v-divider />

            <v-card-actions>
              <v-btn :disabled="step === 1" text @click="onClickBack">{{
                this.$t("_common.Back")
              }}</v-btn>
              <v-spacer />
              <v-btn
                :disabled="step === indexedItems.length"
                color="primary"
                depressed
                @click="onClickNext"
                >{{ this.$t("_common.Next") }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            width="100%"
            style="padding: 0 10em 0 10em"
            v-if="indexedItems.length < 1"
          >
            <br />
            <div class="vs-alert con-icon con-vs-alert-warning">
              <i
                class="
                  vs-icon
                  notranslate
                  icon-scale icon-alert
                  material-icons
                  null
                "
                >new_releases</i
              >
              <p>{{ this.$t("_course_list.No_content_added_to_course") }}</p>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="3" class="p-0">
          <v-data-table
            :headers="headers"
            :items="indexedItems"
            ref="sortableTable"
            item-key="Id"
            single-select
            show-select
            @item-selected="onContentSelect"
            :selectData="selectItem"
            v-model="selectedData"
            hide-default-header
            group-by="ContentGroupId"
            single-expand
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            :height="iframeHeight"
            :no-data-text="this.$t('_common.No_data_available')"
          >
            <template v-slot:group.header="{ items, isOpen, toggle }">
              <th colspan="100%">
                <v-btn text icon small @click="toggle">
                  <v-icon>{{ isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
                </v-btn>
                <span class="mx-2 subtitle-1">{{ items[0].Topic.Title }}</span>
              </th>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <iframe id="my_iframe" style="display: none"></iframe>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VideoPlayer from "./VideoPlayer.vue";
import PdfViewer from "../components/extra-components/pdf-viewer/PdfViewer.vue";
import "videojs-contrib-hls";
import ExamComponent from "./ExamComponent";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Aşağıdaki satırı silmeyiniz. VR için hazırlıktır.!!!!!!
 */
require("neo-xr");

export default {
  data() {
    let _self = this;
    return {
      editorConfig: {
        placeholder: this.$t(" "),
        language: this.$t("_common.LanguageOption"),
      },
      editor: ClassicEditor,
      treeTopicData: [],
      treeTopicDataModel: [],
      nameModel: null,
      successTreeTopic: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "Name",
        },
      ],
      formItems: [],
      itemContentId: null,
      selectItem: null,
      files: null,
      valid: false,
      step: 1,
      selectedData: [],

      drawer: null,
      loading: true,
      mini: false,
      playerOptions: {
        plugins: {
          eventTracking: true,
        },
        autoplay: true,
        fill: true,
        controls: true,
        controlBar: {
          timeDivider: true,
          //durationDisplay: true
          remainingTimeDisplay: true,
        },
        playbackRates: [0.5, 1.0, 1.5, 2.0],
      },
      iframeHeight: "680px",
      videoLoadings: {},
      videoProgress: {},
    };
  },
  methods: {
    downloadVideo(url, id) {
      this.$set(this.videoLoadings, id, true);
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      const vm = this;
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var videoUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = videoUrl;
        tag.target = "_blank";
        const name = url
          .replace(/\.[^/.]+$/, "")
          .split("/")
          .pop();
        const reg = /(?:\.([^.]+))?$/;
        const ext = reg.exec(url)[1];
        tag.download = `${name}.${ext}`;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        vm.videoLoadings[id] = false;
      };
      xhr.onerror = (err) => {
        vm.videoLoadings[id] = false;
        alert("Failed to download video");
      };
      xhr.onprogress = (event) => {
        this.setProgress(id, event.loaded, event.total);
        if (event.loaded === event.total) {
          this.$set(this.videoProgress, id, null);
        }
      };
      xhr.send();
    },
    setProgress(id, curr, total) {
      const percentage = Math.ceil((curr * 100) / total);
      this.$set(this.videoProgress, id, percentage);
    },
    getProgress(id) {
      if (this.videoProgress[id]) {
        return `${this.videoProgress[id]}/100`;
      }
      return false;
    },
    onPlayerReady(player) {
      let video;
      if (
        this.selectedData[0].ContentProperty.CustomContent &&
        this.selectedData[0].ContentProperty.CustomContent != "null"
      ) {
        let childVideos = JSON.parse(
          this.selectedData[0].ContentProperty.CustomContent
        );
        let videos = [];
        videos.push({
          type: "video/mp4",
          src: this.selectedData[0].ContentProperty.Url,
          label: "HD",
          selected: true,
        });
        for (const childVideo of childVideos) {
          let label =
            childVideo.videoMetadata.resolution.split("x")[1] === "480"
              ? "SD"
              : "LD";
          videos.push({
            type: "video/mp4",
            src: childVideo.url,
            label: label,
          });
        }

        video = videos;
      } else {
        video = {
          type: "video/mp4",
          src: this.selectedData[0].ContentProperty.Url,
        };
      }
      player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,

      if (this.selectedData[0].ContentProperty.VideoIs360) {
        /**
         * Aşağıdaki satırı silmeyiniz. VR için hazırlıktır.!!!!!!
         */
        player.xr();
        // player.mediainfo = player.mediainfo || {};
        // player.mediainfo.projection = "360";
        // player.vr({
        //   projection: "AUTO",
        //   debug: true,
        //   forceCardboard: false
        // });
      }

      if (this.selectedData[0].ContentProperty.MandatoryWatch) {
        player.controlBar.progressControl.disable();
        // player.controlBar.playToggle.disable();
        player.controlBar.playbackRateMenuButton.disable();
      }

      let contentSubtitles =
        this.selectedData[0].ContentProperty.ContentSubtitles;
      if (contentSubtitles.length > 0) {
        for (const contentSubtitle of contentSubtitles) {
          player.addRemoteTextTrack(
            {
              kind: contentSubtitle.Kind,
              label: contentSubtitle.Language,
              src: contentSubtitle.Url,
            },
            true
          );
        }
        player.textTracks()[0].mode = "showing";
        player.textTrackSettings.setValues({
          backgroundColor: "#000",
          backgroundOpacity: "0",
          color: "#FFF",
          edgeStyle: "dropshadow",
          fontFamily: "proportionalSansSerif",
          textOpacity: "1",
          windowColor: "#000",
          windowOpacity: "0",
        });
      }
      // player.crossOrigin('anonymous');
      player.setAttribute("crossorigin", "anonymous");
      player.src(video);

      player.load();
      player.play();
    },

    init() {
      if (this.$route.params.topicId) {
        this.successTreeTopic = true;
        this.refresh(this.$route.params.topicId);
      } else {
        this.successTreeTopic = false;
      }
      this.treeTopicDataModel = this.$route.params.topicId;
    },
    resizeIframe(obj) {
      obj.style.height =
        obj.contentWindow.document.documentElement.scrollHeight + "px";
    },
    refresh(id) {
      if (!id) return;
      this.$goc.request.get(
        this.$enums.API.Content_For_Course + id,
        (response) => {
          this.formItems = response.Result.Contents;
        }
      );
    },
    onContentSelect(row) {
      this.step = parseInt(row.item.index);
    },
    onClickNext() {
      this.step++;
    },
    onClickBack() {
      this.step--;
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: "video/mp4",
        src: source,
      };
      player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      player.src(video);
      player.play();
    },
    handleIframeResize(e) {
      for (var b in e.data["datawrapper-height"]) {
        if ("8dfPN" === b) {
          this.iframeHeight = `${e.data["datawrapper-height"][b]}px`;
        }
      }
    },
    getType() {
      return this.$enums.EXAM_TYPE.QUIZ;
    },

    checkUserAssignment() {
      let body = {
        userId: JSON.parse(localStorage.getItem("userInfo")).Uid,
        topicId: this.$route.params.topicId,
      };

      this.$goc.request.post(this.$enums.API.CheckUserAssignment, body, () => {
        this.initCoursePage();
      });
    },

    initCoursePage() {
      this.init();
      this.loading = true;
      this.iframeHeight = `{DELTAS[Math.min(1e3,Math.max(100*Math.floor(this.iframeOffsetWidth/100),100))]}px`;
      // setup event listener
      window.addEventListener("message", this.handleIframeResize);
    },
    /* videoPaused(){
           if(player().paused())
           {
             player.currentTime();
             this.$goc.console.log(player().currentTime());
           }

      } */
  },
  props: {
    source: String,
  },
  components: {
    VuePerfectScrollbar,
    VideoPlayer,
    ExamComponent,
    PdfViewer,
  },

  created() {
    this.checkUserAssignment();
  },

  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    indexedItems() {
      return this.formItems.map((item, index) => ({
        index: index + 1,
        ContentGroupId: item.Topic.Id,
        ...item,
      }));
    },
    iframeOffsetWidth() {
      return this.$refs["unrealIframe"].offsetWidth;
    },
  },
  watch: {
    step: function (val) {
      for (const item of this.indexedItems) {
        if (item.index === val) {
          this.selectedData = [];
          this.selectedData.push(item);
        }
      }
      if (this.$refs.videoPlayer) {
        for (const videoPlayerElement of this.$refs.videoPlayer) {
          videoPlayerElement.player.pause();
        }
      }
    },
    indexedItems: function (val) {
      for (const item of val) {
        if (item.index === 1) {
          this.selectedData = [];
          this.selectedData.push(item);
        }
      }
    },
  },
  beforeDestroy() {
    // destroy event listener
    window.removeEventListener("message", this.handleIframeResize);
  },
};
</script>

<style lang="scss">
.detail-text {
  .ck-editor {
    .ck-sticky-panel {
      display: none;
    }
  }
}
.video-js .vjs-tech {
  position: inherit;
}
.loading-btn {
  width: 120px;
}
button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

.scroll-area {
  position: relative;
  margin: auto;
  width: auto;
  height: 18rem;
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}
</style>
